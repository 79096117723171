<template>
    <div>
        <div class="home-bg1">
            <div class="cycle-wrapper">
                <div class="breadcrumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>关于迪倍特</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="content">
                    <div class="con-title">公司介绍</div>
                    <div class="con-text">
                        西安迪倍特信息科技有限公司，专业为客户提供企业信息化设备运维管理云平台解决方案和电气自动化网络控制系统和设备运维技术支持等服务。
                    </div>
                    <div class="con-text">
                        公司专注于LoRa、NB-IoT、ZigBee、2.5G、3G、4G、5G等无线通讯模组和物联网测控终端产品的成套系统设计、编程开发、部署、运维及技术支持服务，为行业用户，系统集成商，运营商提供有竞争力的产品、技术、方案和服务。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
// web端样式
.home-bg1 {
    user-select: none;
    width: 100%;
    height: 100vh;
    margin-top: -90px;
    background-image: url('../../img/bg1.png');
    background-size: cover;
    position: relative;
    overflow: auto;
    .cycle-wrapper {
        padding: 90px 30px 0;
        color: #FFFFFF;
        .content {
            padding: 5px;
            margin: 0 35px;
            .con-title {
                font-size: 16px;
                width: 100px;
                height: 30px;
                background: #ffffff;
                border-radius: 15px;
                color: rgb(103, 103, 103);
                text-align: center;
                line-height: 30px;
                margin: 10px 0 20px;
            }
            .con-text {
                font-size: 16px;
                line-height: 35px;
                text-indent: 30px;
                letter-spacing: 2px;
            }
        }
    }
}

// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>